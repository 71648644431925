import { exportCallLogs, getPastCallLogs } from 'api/calllogs';
import { getHealthSystems, getOrgTeamChildren } from 'api/users';
import { Badge, Grid, Modal, Table } from 'components';
import CallDetails from 'components/CallLogs/CallDetails';
import Pagination from 'components/Common/Pagination';
import { BanyanCallTypesMessage, CallStatus, CallTypes, DeviceListLevel, ObjectType, ParticipantState, UserRoles } from 'constants/enums';
import { getUserProfile, getUserRole } from 'infrastructure/auth';
import { convertSecondsToHoursFormat, formatDate, getDateDifference, utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import moment from 'moment';
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { parseDate } from 'react-day-picker/moment';
import Select from 'react-select';

class PastCallsList extends Component {
	state = {
		logs: [],
		loading: false,
		pageSize: { value: 10, label: '10' },
		pageIndex: 0,
		totalCount: 0,
		from: undefined,
		to: undefined,
		selectedHealthSystem: null,
		selectedRegion: null,
		selectedHospital: null,
		selectedDepartment: null,
		selectedFloor: null,
		selectedRoom: null,
		healthSystems: [],
		regions: [],
		hospitals: [],
		departments: [],
		floors: [],
		rooms: [],
		currentLevel: DeviceListLevel.ORGANIZATION,
		currentTeamId: null,
		showCallQueueModal: false,
		showCallDetailsModal: false,
		showListOfRoomsModal: false,
		showStatAlarmsModal: false,
		currentCallLogQueue: [],
		selectedConference: {},
		selectedConferenceRoom: {},
		isExportLogsModalOpen: false,
		dateError: '',
		isExportLogsModalLoading: false,
		hasExportLogsModalErrors: false,
	};

	userProfile = getUserProfile();

	allObj = { label: 'All', value: null };

	async componentDidMount() {
		const healthSystems = await getHealthSystems();
		const healthSystemsOptions = healthSystems.map(hs => {
			return { value: hs.id, label: hs.name };
		});

		if (getUserRole() === UserRoles.ADMIN) {
			healthSystemsOptions.unshift(this.allObj);
		}

		this.setState({
			healthSystems: healthSystemsOptions,
		});

		if (getUserRole() !== UserRoles.ADMIN) {
			this.filterByLevel(healthSystems[0].id, DeviceListLevel.HEALTHSYSTEM);

			const regions = await getOrgTeamChildren(healthSystems[0].id);
			const regionsOptions = regions.map(r => {
				return { value: r.id, label: r.name };
			});
			regionsOptions.unshift(this.allObj);

			this.setState({
				selectedHealthSystem: { value: healthSystems[0].id, label: healthSystems[0].name },
				currentLevel: DeviceListLevel.HEALTHSYSTEM,
				currentTeamId: healthSystems[0].id,
				healthSystems: healthSystemsOptions,
				regions: regionsOptions,
			});
			return;
		}
		this.onInitPastCallLogs();
	}

	getCallType(callType, initiator) {
		if (callType === CallTypes.AUDIO && initiator === 'Virtual Care Provider') {
			return BanyanCallTypesMessage.TALK_TO_PATIENT;
		}
		if (callType === CallTypes.AUDIO && initiator === 'Patient') {
			return BanyanCallTypesMessage.TALK_TO_NURSE;
		}
		if (callType === CallTypes.VIDEO) {
			return BanyanCallTypesMessage.VIDEO_CALL;
		}
		if (callType === CallTypes.SECURITYCAM) {
			return BanyanCallTypesMessage.PATIENT_VIEW;
		}
		if (callType === CallTypes.MONITORING) {
			return BanyanCallTypesMessage.MONITORING;
		}
		return undefined;
	}

	isSuccessfulCall(status) {
		if (status === CallStatus.FAILED.type || status === CallStatus.MISSED.type || status === CallStatus.ABORTED.type) return false;

		return true;
	}

	getLogs(conferences) {
		return conferences.map(conference => {
			const room =
				conference.callType === CallTypes.MONITORING ? this.getMonitoredRooms(conference.participants) : this.getPatientRoom(conference.participants);
			const vcp = this.getVirtualCareProvider(conference.participants, conference.status);
			const status = this.getCallStatus(conference.status);
			const startedAt = moment(utcToLocalTime(conference.startedAt)).format('MM/DD/YYYY-hh:mm A');
			const endedAt = moment(utcToLocalTime(conference.endedAt)).format('MM/DD/YYYY-hh:mm A');
			const initiator = vcp?.isInitiator ? 'Virtual Care Provider' : 'Patient';
			const rooms = conference.participants.filter(p => p.objectType === ObjectType.HELLO_DEVICE);
			return {
				id: conference.id,
				initiator: initiator,
				provider: (conference.callType !== CallTypes.MONITORING && room.isInitiator && !this.isSuccessfulCall(conference.status)) || !vcp ? 'N/A' : vcp.name,
				room: conference.callType === CallTypes.MONITORING ? room : room.name,
				startedAt: startedAt,
				endedAt: endedAt,
				origin: vcp?.isInitiator ? 'Outgoing' : 'Incoming',
				status: status,
				callType: this.getCallType(conference.callType, initiator),
				duration: conference.callDuration,
				usedAudio: { room: room.usedAudio, vcp: vcp?.usedAudio },
				usedVideo: { room: room.usedVideo, vcp: vcp?.usedVideo },
				usedScreen: { room: room.usedScreen, vcp: vcp?.usedScreen },
				participants: conference.participants,
				queue: room.isInitiator ? conference.participants.filter(participant => participant.objectType === ObjectType.USER) : [],
				roomCount: rooms.length,
			};
		});
	}

	getMonitoredRooms(participants) {
		const rooms = participants.reduce((acc, value) => {
			if (value.objectType === ObjectType.HELLO_DEVICE) {
				return `${acc}${acc && ','}${value.name}`;
			}
			return acc;
		}, '');

		return rooms;
	}

	getTableRows() {
		if (this.state.logs.length === 0) return [];
		return this.state.logs.map(log => {
			return {
				conferenceId: log.id,
				initiator: log.initiator,
				provider: log.provider,
				room:
					log.callType === BanyanCallTypesMessage.MONITORING ? (
						<span data-cy='listOfRooms' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.openListOfRoomsModal(log)}>
							View All
						</span>
					) : (
						log.room
					),
				date: (
					<div style={{ fontSize: '12px' }}>
						<span style={{ display: 'block' }}>{log.startedAt}</span>
						<span style={{ display: 'block' }}>{log.endedAt}</span>
					</div>
				),
				origin: log.origin,
				status: this.getStatusBadge(log.status),
				duration: log.duration,
				callType: log.callType,
				details: (
					<span data-cy='callDetails' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.openCallDetailsModal(log)}>
						View Details
					</span>
				),
				queue:
					log.queue.length > 0 ? (
						<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.openCallLogQueueModal(log.queue)}>
							View All
						</span>
					) : (
						'N/A'
					),
			};
		});
	}

	openCallLogQueueModal(participants) {
		this.setState({
			showCallQueueModal: true,
			currentCallLogQueue: participants,
		});
	}

	openCallDetailsModal(conference) {
		this.setState({
			showCallDetailsModal: true,
			selectedConference: conference,
		});
	}

	startExportTask = async () => {
		this.setState({
			isExportLogsModalLoading: true,
		});
		const { error } = await exportCallLogs({
			start: Math.round(new Date(this.state.from).getTime() / 1000),
			end: Math.round(new Date(this.state.to).getTime() / 1000),
			healthSystemId: this.state.selectedHealthSystem?.value ?? null,
			regionId: this.state.selectedRegion?.value ?? null,
			hospitalId: this.state.selectedHospital?.value ?? null,
			departmentId: this.state.selectedDepartment?.value ?? null,
			floorId: this.state.selectedFloor?.value ?? null,
			roomId: this.state.selectedRoom?.value ?? null,
		});

		this.setState({
			isExportLogsModalLoading: false,
			hasExportLogsModalErrors: !!error,
			isExportLogsModalOpen: !!error,
		});
	};

	getVirtualCareProvider(participants, status) {
		if (status === CallStatus.SUCCESSFUL.type && participants.length > 2) {
			return participants.find(participant => {
				return participant.objectType === ObjectType.USER && participant.isInitiator;
			});
		}

		return participants.find(participant => {
			return participant.objectType === ObjectType.USER;
		});
	}

	getPatientRoom(participants) {
		return participants.find(participant => {
			return participant.objectType === ObjectType.HELLO_DEVICE;
		});
	}

	getCallStatus(status) {
		switch (status) {
			case CallStatus.UNDEFINED.type: {
				return CallStatus.UNDEFINED.message;
			}
			case CallStatus.SUCCESSFUL.type: {
				return CallStatus.SUCCESSFUL.message;
			}
			case CallStatus.PARTIALLYSUCCESSFUL.type: {
				return CallStatus.PARTIALLYSUCCESSFUL.message;
			}
			case CallStatus.FAILED.type: {
				return CallStatus.FAILED.message;
			}
			case CallStatus.DISRUPTED.type: {
				return CallStatus.DISRUPTED.message;
			}
			case CallStatus.PARTIALLYDISRUPTED.type: {
				return CallStatus.PARTIALLYDISRUPTED.message;
			}
			case CallStatus.MISSED.type: {
				return CallStatus.MISSED.message;
			}
			case CallStatus.ABORTED.type: {
				return CallStatus.ABORTED.message;
			}
			case CallStatus.DROPPED.type: {
				return CallStatus.DROPPED.message;
			}
			// no default
		}
	}

	getStatusBadge(status) {
		return <Badge text={status} variant={status === CallStatus.SUCCESSFUL.message ? 'green' : 'red'} />;
	}

	onChange = async (pageSize, pageIndex, start, end) => {
		const startDate = start || this.state.from;
		const endDate = end || this.state.to;
		const result = await getPastCallLogs({
			start: startDate ? Math.round(new Date(startDate).getTime() / 1000) : null,
			end: endDate ? Math.round(new Date(endDate).getTime() / 1000) : null,
			healthSystemId: this.state.selectedHealthSystem?.value,
			regionId: this.state.selectedRegion?.value,
			hospitalId: this.state.selectedHospital?.value,
			departmentId: this.state.selectedDepartment?.value,
			floorId: this.state.selectedFloor?.value,
			roomId: this.state.selectedRoom?.value,
			pageSize: this.state.pageSize?.value,
			pageIndex: this.state.pageIndex,
		});
		if (result.error) {
			return;
		}
		const logs = this.getLogs(result.conferences);
		this.setState({
			logs,
			loading: false,
			pageSize,
			pageIndex,
			totalCount: result.totalCount,
		});
	};

	showFromMonth() {
		const { from, to } = this.state;
		if (!from) {
			return;
		}
		if (moment(to).diff(moment(from), 'months') < 2) {
			this.to.getDayPicker().showMonth(from);
		}
	}

	handleFromChange = from => {
		const daysDiff = this.state.selectedHealthSystem === this.allObj ? 1 : 90;
		const diff = getDateDifference(from, this.state.to);
		if (diff > daysDiff) {
			this.setState({
				dateError: `Date difference cant be more than ${daysDiff} days`,
				from,
			});
		} else {
			this.setState({ from, dateError: '' });
			if (from && !this.state.to) {
				return;
			}
			this.onChange(this.state.pageSize, 0, this.state.to?.setHours(0, 0, 0, 0), from?.setHours(23, 59, 59));
		}
	};

	handleToChange = to => {
		const daysDiff = this.state.selectedHealthSystem === this.allObj ? 1 : 90;
		const diff = getDateDifference(this.state.from, to);
		if (diff > daysDiff) {
			this.setState({
				dateError: `Date difference cant be more than ${daysDiff} days`,
				to,
			});
		} else {
			this.setState({ to, dateError: '' }, this.showFromMonth);
			if (!this.state.from && to) {
				return;
			}
			this.onChange(this.state.pageSize, 0, this.state.from?.setHours(0, 0, 0, 0), to?.setHours(23, 59, 59));
		}
	};

	handleResetClick = () => {
		this.setState({ from: undefined, to: undefined });
	};

	async filterByLevel(value, type, isInit = false) {
		this.setState({
			loading: true,
			pageIndex: 0,
			dateError: '',
		});

		let start = this.state.from ? Math.round(new Date(this.state.from).getTime() / 1000) : null;
		let end = this.state.to ? Math.round(new Date(this.state.to).getTime() / 1000) : null;

		if (type === DeviceListLevel.ORGANIZATION && isInit) {
			const oneDayBefore = new Date();
			oneDayBefore.setDate(oneDayBefore.getDate() - 1);
			const endDate = new Date();
			const formattedStart = oneDayBefore.setHours(0, 0, 0, 0);
			const formattedEnd = endDate.setHours(23, 59, 59, 999);
			start = Math.round(new Date(formattedStart).getTime() / 1000);
			end = Math.round(new Date(formattedEnd).getTime() / 1000);

			this.setState({
				from: oneDayBefore,
				to: endDate,
			});
		}

		const result = await getPastCallLogs({
			start,
			end,
			healthSystemId: type === DeviceListLevel.HEALTHSYSTEM ? value : this.state.selectedHealthSystem?.value,
			regionId: type === DeviceListLevel.REGION ? value : this.state.selectedRegion?.value,
			hospitalId: type === DeviceListLevel.HOSPITAL ? value : this.state.selectedHospital?.value,
			departmentId: type === DeviceListLevel.DEPARTMENT ? value : this.state.selectedDepartment?.value,
			floorId: type === DeviceListLevel.FLOOR ? value : this.state.selectedFloor?.value,
			roomId: type === DeviceListLevel.ROOM ? value : this.state.selectedRoom?.value,
			pageSize: this.state.pageSize.value,
			pageIndex: this.state.pageIndex,
		});
		if (result.error) {
			return;
		}
		const logs = this.getLogs(result.conferences);
		this.setState({
			logs: logs,
			loading: false,
			totalCount: result.totalCount,
		});
	}

	async onHealthSystemSelected(value) {
		if (!value?.value) {
			this.onInitPastCallLogs();
			return;
		}
		this.setState({
			selectedHealthSystem: value,
			selectedRegion: this.allObj,
			selectedHospital: null,
			selectedDepartment: null,
			selectedFloor: null,
			selectedRoom: null,
			regions: [],
			hospitals: [],
			departments: [],
			floors: [],
			rooms: [],
			currentLevel: DeviceListLevel.HEALTHSYSTEM,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.HEALTHSYSTEM);

		const regions = await getOrgTeamChildren(value.value);
		const regionsOptions = regions.map(r => {
			return { value: r.id, label: r.name };
		});
		regionsOptions.unshift(this.allObj);
		this.setState({ regions: regionsOptions });
	}

	async onInitPastCallLogs() {
		if (this.state.from && this.state.to) {
			const diff = getDateDifference(this.state.from, this.state.to);
			if (diff > 1) {
				this.setState({
					dateError: `Date difference cant be more than 1 day`,
				});
				return;
			}
		}

		this.setState({
			selectedHealthSystem: this.allObj,
			selectedRegion: null,
			selectedHospital: null,
			selectedDepartment: null,
			selectedFloor: null,
			selectedRoom: null,
			regions: [],
			hospitals: [],
			departments: [],
			floors: [],
			rooms: [],
			dateError: false,
		});

		this.filterByLevel(null, DeviceListLevel.ORGANIZATION, true);
	}

	async onRegionSelected(value) {
		this.setState({
			selectedRegion: value,
			selectedHospital: this.allObj,
			selectedDepartment: null,
			selectedFloor: null,
			selectedRoom: null,
			hospitals: [],
			departments: [],
			floors: [],
			rooms: [],
			currentLevel: DeviceListLevel.REGION,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.REGION);

		const hospitals = await getOrgTeamChildren(value.value);
		const hospitalOptions = hospitals.map(h => {
			return { value: h.id, label: h.name };
		});
		hospitalOptions.unshift(this.allObj);
		this.setState({ hospitals: hospitalOptions });
	}

	async onHospitalSelected(value) {
		this.setState({
			selectedHospital: value,
			selectedDepartment: this.allObj,
			selectedFloor: null,
			selectedRoom: null,
			departments: [],
			floors: [],
			rooms: [],
			currentLevel: DeviceListLevel.HOSPITAL,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.HOSPITAL);

		const departments = await getOrgTeamChildren(value.value);
		const departmentOptions = departments.map(d => {
			return { value: d.id, label: d.name };
		});
		this.setState({ departments: departmentOptions });
	}

	async onDepartmentSelected(value) {
		this.setState({
			selectedDepartment: value,
			selectedFloor: this.allObj,
			selectedRoom: null,
			floors: [],
			rooms: [],
			currentLevel: DeviceListLevel.DEPARTMENT,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.DEPARTMENT);

		const floors = await getOrgTeamChildren(value.value);
		const floorOptions = floors.map(f => {
			return { value: f.id, label: f.name };
		});
		floorOptions.unshift(this.allObj);
		this.setState({ floors: floorOptions });
	}

	async onFloorSelected(value) {
		this.setState({
			selectedFloor: value,
			selectedRoom: this.allObj,
			rooms: [],
			currentLevel: DeviceListLevel.FLOOR,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.FLOOR);

		const rooms = await getOrgTeamChildren(value.value);
		const roomOptions = rooms.map(r => {
			return { value: r.id, label: r.name };
		});
		roomOptions.unshift(this.allObj);
		this.setState({ rooms: roomOptions });
	}

	async onRoomSelected(value) {
		this.setState({
			selectedRoom: value,
			currentLevel: DeviceListLevel.ROOM,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.ROOM);
	}

	showListOfUnavailableNurses() {
		const headers = [{ title: 'Name' }, { title: 'Status' }];

		const unavailableNurses = this.state.currentCallLogQueue.map(nurse => {
			return {
				name: nurse.name,
				status: this.getParticipantState(nurse.state),
			};
		});

		return <Table isLoading={this.state.loading} headers={headers} rows={unavailableNurses} />;
	}

	getParticipantState(state) {
		switch (state) {
			case ParticipantState.CONNECTING.type: {
				return ParticipantState.CONNECTING.message;
			}
			case ParticipantState.CONNECTED.type: {
				return ParticipantState.CONNECTED.message;
			}
			case ParticipantState.BUSY.type: {
				return ParticipantState.BUSY.message;
			}
			case ParticipantState.DECLINED.type: {
				return ParticipantState.DECLINED.message;
			}
			case ParticipantState.OFFLINE.type: {
				return ParticipantState.OFFLINE.message;
			}
			case ParticipantState.LEFT_CALL.type: {
				return ParticipantState.LEFT_CALL.message;
			}
			case ParticipantState.NOT_ANSWERING.type: {
				return ParticipantState.NOT_ANSWERING.message;
			}
			case ParticipantState.CANT_CONNECT.type: {
				return ParticipantState.CANT_CONNECT.message;
			}
			case ParticipantState.DISRUPTED.type: {
				return ParticipantState.DISRUPTED.message;
			}
			case ParticipantState.REMOVED.type: {
				return ParticipantState.REMOVED.message;
			}
			default: {
				return 'N/A';
			}
		}
	}

	openListOfRoomsModal = conference => {
		this.setState({
			selectedConference: conference,
			showListOfRoomsModal: true,
		});
	};

	openStatAlarmsModal = conferenceRoom => {
		this.setState({
			selectedConferenceRoom: conferenceRoom,
			showStatAlarmsModal: true,
		});
	};

	showListOfRooms = () => {
		const headers = [{ title: 'Room' }, { title: 'Status' }, { title: 'Stat Alarms' }];
		const conference = this.state.selectedConference;
		if (!conference || !conference.participants) {
			// eslint-disable-next-line no-console
			return console.error('Error while getting list of participants!');
		}

		const rooms = conference.participants.reduce((acc, participant) => {
			if (participant.objectType === ObjectType.HELLO_DEVICE) {
				acc.push({
					room: participant.name,
					status: this.getParticipantState(participant.state),
					statAlarms:
						participant.statAlarms?.length > 0 ? (
							<span data-cy='listOfStatAlarms' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.openStatAlarmsModal(participant)}>
								View All
							</span>
						) : (
							'N/A'
						),
				});
			}
			return acc;
		}, []);

		return <Table isLoading={this.state.loading} headers={headers} rows={rooms} tableEmptyText='No rooms at the moment.' />;
	};

	showListOfStatAlarms = () => {
		const headers = [{ title: 'Activation Time' }, { title: 'Deactivation Time' }, { title: 'Duration' }];
		const conferenceRoom = this.state.selectedConferenceRoom;
		if (!conferenceRoom || !conferenceRoom.statAlarms) {
			return;
		}

		const statAlarms = conferenceRoom.statAlarms.map(statAlarm => ({
			activationTime: statAlarm.dateEnabled ? moment(utcToLocalTime(statAlarm.dateEnabled)).format('MM/DD/YYYY-hh:mm A') : 'N/A',
			deactivationTime: statAlarm.dateDisabled ? moment(utcToLocalTime(statAlarm.dateDisabled)).format('MM/DD/YYYY-hh:mm A') : 'N/A',
			duration: statAlarm.dateEnabled && statAlarm.dateDisabled ? convertSecondsToHoursFormat(statAlarm.durationInSeconds) : 'N/A',
		}));

		// eslint-disable-next-line consistent-return
		return <Table headers={headers} rows={statAlarms} tableEmptyText='No stat alarms were activated.' />;
	};

	render() {
		const tableHeaders = [
			{ title: 'ID' },
			{ title: 'Call Initiator' },
			{ title: 'Virtual Care Provider' },
			{ title: 'Room' },
			{ title: 'Date/Time' },
			{ title: 'Call Origin' },
			{ title: 'Call Status' },
			{ title: 'Call Duration' },
			{ title: 'Call Type' },
			{ title: 'Details' },
			{ title: 'Queue' },
		];
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};
		return (
			<div>
				<Table isLoading={this.state.loading} headers={tableHeaders} rows={this.state.loading ? [] : this.getTableRows()}>
					<Grid columns='200px repeat(7, 1fr)' gridGap='10px'>
						<Select
							value={this.state.selectedHealthSystem}
							placeholder='Health System'
							classNamePrefix='custom-select'
							options={this.state.healthSystems}
							components={{ DropdownIndicator }}
							onChange={value => this.onHealthSystemSelected(value)}
						/>
						<Select
							value={this.state.selectedRegion}
							placeholder='Location'
							classNamePrefix='custom-select'
							options={this.state.regions}
							components={{ DropdownIndicator }}
							onChange={value => this.onRegionSelected(value)}
						/>
						<Select
							value={this.state.selectedHospital}
							placeholder='Hospital'
							classNamePrefix='custom-select'
							options={this.state.hospitals}
							components={{ DropdownIndicator }}
							onChange={value => this.onHospitalSelected(value)}
						/>
						<Select
							value={this.state.selectedDepartment}
							placeholder='Department'
							classNamePrefix='custom-select'
							options={this.state.departments}
							components={{ DropdownIndicator }}
							onChange={value => this.onDepartmentSelected(value)}
						/>
						<Select
							value={this.state.selectedFloor}
							placeholder='Floor'
							classNamePrefix='custom-select'
							options={this.state.floors}
							components={{ DropdownIndicator }}
							onChange={value => this.onFloorSelected(value)}
						/>
						<Select
							value={this.state.selectedRoom}
							placeholder='Room'
							classNamePrefix='custom-select'
							options={this.state.rooms}
							components={{ DropdownIndicator }}
							onChange={value => this.onRoomSelected(value)}
						/>
						<div>
							<div className='InputFromTo'>
								<i className='material-icons'>date_range</i>
								<DayPickerInput
									value={from}
									placeholder='--/--/----'
									format='LL'
									formatDate={formatDate}
									parseDate={parseDate}
									dayPickerProps={{
										selectedDays: [from, { from, to }],
										disabledDays: { after: to },
										toMonth: to,
										modifiers,
										numberOfMonths: 2,
										onDayClick: () => this.to.getInput().focus(),
									}}
									onDayChange={this.handleFromChange}
								/>{' '}
								-{' '}
								<span className='InputFromTo-to'>
									<DayPickerInput
										ref={el => (this.to = el)}
										value={to}
										placeholder='--/--/----'
										format='LL'
										formatDate={formatDate}
										parseDate={parseDate}
										dayPickerProps={{
											selectedDays: [from, { from, to }],
											disabledDays: { before: from },
											modifiers,
											month: from,
											fromMonth: from,
											numberOfMonths: 2,
										}}
										onDayChange={this.handleToChange}
									/>
								</span>
								<i className='material-icons'>arrow_drop_down</i>
							</div>

							{this.state.dateError && <p style={{ color: 'red', fontSize: 12 }}>{this.state.dateError}</p>}
						</div>

						<div
							className={`button download ${this.state.to && this.state.from && !this.state.dateError ? '' : 'disabled'}`}
							data-cy='exportAsCSV'
							onClick={() => {
								this.setState({ isExportLogsModalOpen: true });
							}}>
							<i className='material-icons'>attach_email</i>Export to CSV
						</div>
					</Grid>
				</Table>
				<Pagination
					totalCount={this.state.totalCount}
					pageSize={this.state.pageSize}
					pageIndex={this.state.pageIndex}
					onChange={(pageSize, pageIndex) => this.onChange(pageSize, pageIndex)}
				/>
				<Modal
					display={this.state.showCallQueueModal}
					position='center'
					hideActionButtons={true}
					onModalSubmit={null}
					onModalClose={() => this.setState({ showCallQueueModal: false, currentCallLogQueue: [] })}>
					<form>
						<h3>Queue of nurses that were called</h3>
						{this.showListOfUnavailableNurses()}
					</form>
				</Modal>
				<Modal
					modalSelector='callDetailsModal'
					display={this.state.showCallDetailsModal}
					position='center'
					hideActionButtons={true}
					onModalSubmit={null}
					onModalClose={() => this.setState({ showCallDetailsModal: false, selectedConference: {} })}>
					<CallDetails selectedConference={this.state.selectedConference} />
				</Modal>
				<Modal
					modalSelector='listOfRoomsModal'
					display={this.state.showListOfRoomsModal}
					position='center'
					hideActionButtons={true}
					onModalSubmit={null}
					onModalClose={() => this.setState({ showListOfRoomsModal: false, selectedConference: {} })}>
					<form>
						<h3>List of Rooms</h3>
						{this.state.showListOfRoomsModal && this.showListOfRooms()}
					</form>
				</Modal>
				<Modal
					modalSelector='listOfStatAlarmsModal'
					display={this.state.showStatAlarmsModal}
					position='center'
					hideActionButtons={true}
					onModalSubmit={null}
					onModalClose={() => this.setState({ showStatAlarmsModal: false, selectedConferenceRoom: {} })}>
					<form>
						<h3>List of Stat Alarms</h3>
						{this.state.showStatAlarmsModal && this.showListOfStatAlarms()}
					</form>
				</Modal>
				<Modal
					display={this.state.isExportLogsModalOpen}
					position='center'
					isLoading={this.state.isExportLogsModalLoading}
					onModalSubmit={this.startExportTask}
					submitButtonText='Continue'
					onModalClose={() => this.setState({ isExportLogsModalOpen: false, isExportLogsModalLoading: false, hasExportLogsModalErrors: false })}>
					<form>
						<h3>Export CSV Logs</h3>
						{!this.state.hasExportLogsModalErrors && (
							<p>{`The CSV export will be created in the background. Once finished, it will be sent to ${this.userProfile.email} in an attachment.`}</p>
						)}
						{this.state.hasExportLogsModalErrors && <p>Something went wrong. Please try again later</p>}
					</form>
				</Modal>
			</div>
		);
	}
}

export default PastCallsList;
